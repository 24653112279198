"use strict";

$(document).ready(function($) {

    var currentSelect = function currentSelect(selector) {

        if (!$('selector').length) {
            return false;
        }

        var select = document.querySelector(selector),
            selectHeader = select.querySelector('.form-select__header'),
            selectItem = select.querySelectorAll('.form-select__item');
        selectHeader.addEventListener('click', selectActiveToggle);
        selectItem.forEach(function (item) {
            item.addEventListener('click', selectChoose);
        });

        function selectActiveToggle() {
            select.classList.toggle('is-active');
        }

        function selectChoose() {
            var value = this.innerText,
                dataValue = this.getAttribute('data-value'),
                valueCurrent = select.querySelector('.form-select__current');
            valueCurrent.innerText = value;
            valueCurrent.setAttribute('data-value', dataValue);
            select.classList.remove('is-active');
        }
    };

    currentSelect('.form-select.target-select');

    /// mask inputs
    $("#phone").mask("+7 (000) 000-0000");
    $("#phone1").mask("+7 (000) 000-0000");
    $("#phone2").mask("+7 (000) 000-0000");
    $("#phone3").mask("+7 (000) 000-0000");

    //stats form
    $('#stat_table').on('focusin', '[name="date"]', function() {
        $(this).closest('tr').find('[name="date"]').mask('99.99.9999', {placeholder: 'дд.мм.гггг'});
    });

    $('#stat_table').on('focusin', '[name="time"]', function() {
        $(this).closest('tr').find('[name="time"]').mask('00:00:00', {placeholder: "чч:мм:сс"});
    });

    $('#stat_table').on('focusin', '[name="depth"]', function() {
        $(this).closest('tr').find('[name="depth"]').mask("#0.000", {reverse: true});
    });

    $('#stat_table').on('focusin', '[name="ctrs"]', function() {
        $(this).closest('tr').find('[name="ctrs"]').mask("#0.00", {reverse: true});
    });

    $('#stat_table').on('focusin', '[name="vtr"]', function() {
        $(this).closest('tr').find('[name="vtr"]').mask("#0.00", {reverse: true});
    });


    //submit balance form
    $("#balanceForm").submit(function() { return false; });
    $("#send1").on("click", function(){
        // если обе проверки пройдены
        // сначала мы скрываем кнопку отправки
        $("#send1").replaceWith("<em>отправка...</em>");
        $.ajax({
            type: 'POST',
            url: 'sendmessage.php',
            data: $('#balanceForm').serialize(),
            success: function(data) {
                if(data === "true") {
                    $("#balanceForm").fadeOut("fast", function(){
                        $(this).before("<h3><strong>Ваша заявка отправлена :)</strong></h3>");

                    });
                }
            }
        });
    });

    (function () {
        var campCheck = document.querySelectorAll('.camp-table__check-input');
        campCheck.forEach(function (item) {
            item.addEventListener('change', function () {
                item.closest('tr').classList.toggle('active');
            });
        });
    })();
});